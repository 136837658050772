<template>
  <div>
    <!-- 顶部 -->
    <div class="top">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="院区:">
          <el-select v-model="formInline.campus" @change="topInline">
            <el-option
                v-for="item in Oncampus"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
              v-model="formInline.time"
              type="date"
              @change="timeline"
          />
        </el-form-item>
        <el-form-item label="时段:">
          <el-select v-model="formInline.region" @change="topInline">
            <el-option
                v-for="item in shiduan"
                :key="item.id"
                :label="item.name"
                :value="item.name"
            >

            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 中间内容 -->
    <div class="indbody">
      <!-- 卡片 -->
      <el-card class="box-card" v-if="admin_status == 10">
        <template #header>
          <div class="card-header">
            <!-- 左侧 -->
            <div class="indbody_top"></div>
            <!-- 右侧 -->
            <div class="indbody_text">透前概况</div>
          </div>
        </template>
        <div>
          <el-form :model="form">
            <el-form-item label="排班人数：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touqian.ta_pb"
                    color=" rgba(0, 108, 255, 0.6)"
                >
                  {{ form.touqian.ta_pb }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="签到人数：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touqian.tq_qd"
                    color=" rgba(0, 108, 255, 0.6)"
                >
                  {{ form.touqian.tq_qd }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="体重测量：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touqian.tq_tzcl"
                    color=" rgba(0, 108, 255, 0.6)"
                >
                  {{ form.touqian.tq_tzcl }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="血压测量：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touqian.tq_xycl"
                    color=" rgba(0, 108, 255, 0.6)"
                >
                  {{ form.touqian.tq_xycl }}
                </el-progress>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-card>

      <!-- 卡片 -->
      <el-card class="box-card" v-if="admin_status == 10">
        <template #header>
          <div class="card-header">
            <!-- 左侧 -->
            <div class="indbody_top"></div>
            <!-- 右侧 -->
            <div class="indbody_text">透中概况</div>
          </div>
        </template>
        <div>
          <el-form :model="form" class="formwith">
            <el-form-item label="接诊人数：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touzhong.jiezhen"
                    color="rgba(21, 138, 84, 0.6)"
                >
                  {{ form.touzhong.jiezhen }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="上机人数：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touzhong.shangji"
                    color="rgba(21, 138, 84, 0.6)"
                >
                  {{ form.touzhong.shangji }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="核对人数：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touzhong.hedui"
                    color="rgba(21, 138, 84, 0.6)"
                >
                  {{ form.touzhong.hedui }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="执行人数：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touzhong.zhixing"
                    color="rgba(21, 138, 84, 0.6)"
                >
                  {{ form.touzhong.zhixing }}
                </el-progress>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-card>

      <!-- 卡片 -->
      <el-card class="box-card" v-if="admin_status == 10">
        <template #header>
          <div class="card-header">
            <!-- 左侧 -->
            <div class="indbody_top"></div>
            <!-- 右侧 -->
            <div class="indbody_text">透后概况</div>
          </div>
        </template>
        <div>
          <el-form :model="form">
            <el-form-item label="下机人数：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touhou.xiaji"
                    color="rgba(219, 146, 0, 0.6)"
                >
                  {{ form.touhou.xiaji }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="消毒人数：" style="margin: 30px 0">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touhou.xiaodu"
                    color="rgba(219, 146, 0, 0.6)"
                >
                  {{ form.touhou.xiaodu }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="归档人数：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touhou.guidang"
                    color="rgba(219, 146, 0, 0.6)"
                >
                  {{ form.touhou.guidang }}
                </el-progress>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-card>

      <!-- 卡片 -->
      <el-card class="box-card" v-if="admin_status == 10">
        <template #header>
          <div class="card-header">
            <!-- 左侧 -->
            <div class="indbody_top"></div>
            <!-- 右侧 -->
            <div class="indbody_text">血管通路</div>
          </div>
        </template>
        <div>
          <el-form :model="form">
            <el-form-item label="临时中心静脉置管：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.tonglu.lszxjmzg"
                    color="rgba(0, 234, 255, 0.6)"
                >
                  {{ form.tonglu.lszxjmzg }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="长期中心静脉置管：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.tonglu.cqzxjmzg"
                    color="rgba(0, 234, 255, 0.6)"
                >
                  {{ form.tonglu.cqzxjmzg }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="移植血管内瘘：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.tonglu.yzxgnl"
                    color="rgba(0, 234, 255, 0.6)"
                >
                  {{ form.tonglu.yzxgnl }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="直接穿刺：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.tonglu.zjcc"
                    color="rgba(0, 234, 255, 0.6)"
                >
                  {{ form.tonglu.zjcc }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="自体内瘘：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.tonglu.ztnl"
                    color="rgba(0, 234, 255, 0.6)"
                >
                  {{ form.tonglu.ztnl }}
                </el-progress>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-card>

      <!-- 卡片 -->
      <el-card class="box-card" v-if="admin_status == 10" style="overflow-y: scroll">
        <template #header>
          <div class="card-header">
            <!-- 左侧 -->
            <div class="indbody_top"></div>
            <!-- 右侧 -->
            <div class="indbody_text">透析方式</div>
          </div>
        </template>
        <div>
          <el-form :model="form">
            <el-form-item label="HD：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touxi.hd"
                    color="rgba(54, 14, 255, 0.6)"
                >
                  {{ form.touxi.hd }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="HDF：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touxi.hdf"
                    color="rgba(54, 14, 255, 0.6)"
                >
                  {{ form.touxi.hdf }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="HD+HP：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touxi.hd_hp"
                    color="rgba(54, 14, 255, 0.6)"
                >
                  {{ form.touxi.hd_hp }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="HP">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touxi.hp"
                    color="rgba(54, 14, 255, 0.6)"
                >
                  {{ form.touxi.hp }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="HF">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touxi.hf"
                    color="rgba(54, 14, 255, 0.6)"
                >
                  {{ form.touxi.hf }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="HD(高通量)">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touxi.hf_gtl"
                    color="rgba(54, 14, 255, 0.6)"
                >
                  {{ form.touxi.hf_gtl }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="HDF-FX600+HP">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touxi.hdf_fx600_hp"
                    color="rgba(54, 14, 255, 0.6)"
                >
                  {{ form.touxi.hdf_fx600_hp }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="无肝素透析">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touxi.wgstx"
                    color="rgba(54, 14, 255, 0.6)"
                >
                  {{ form.touxi.wgstx }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="HP+HF">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touxi.hp_hf"
                    color="rgba(54, 14, 255, 0.6)"
                >
                  {{ form.touxi.hp_hf }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="HD额外">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touxi.hd_ew"
                    color="rgba(54, 14, 255, 0.6)"
                >
                  {{ form.touxi.hd_ew }}
                </el-progress>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-card>

      <!-- 卡片 -->
      <el-card class="box-card" v-if="admin_status == 10">
        <template #header>
          <div class="card-header">
            <!-- 左侧 -->
            <div class="indbody_top"></div>
            <!-- 右侧 -->
            <div class="indbody_text">特殊情况</div>
          </div>
        </template>
        <div>
          <el-form :model="form">
            <el-form-item label="状况人数：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.teshu.zhuangkuang"
                    color="rgba(255, 0, 83, 0.6)"
                >
                  {{ form.teshu.zhuangkuang }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="脱管人数：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.teshu.tuoguan"
                    color="rgba(255, 0, 83, 0.6)"
                >
                  {{ form.teshu.tuoguan }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="坠床人数：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.teshu.zhuichuang"
                    color="rgba(255, 0, 83, 0.6)"
                >
                  {{ form.teshu.zhuichuang }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="并发症：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.teshu.bingfazheng"
                    color="rgba(255, 0, 83, 0.6)"
                >
                  {{ form.teshu.bingfazheng }}
                </el-progress>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-card>

      <!-- 卡片 -->
      <el-card class="box-card" v-if="role_type == 10">
        <template #header>
          <div class="card-header">
            <!-- 左侧 -->
            <div class="indbody_top"></div>
            <!-- 右侧 -->
            <div class="indbody_text">透析机总数</div>
          </div>
        </template>
        <div>
          <el-form :model="form">
            <el-form-item label="透析机数：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touxiji.all_num"
                    color="rgba(0, 108, 255, 0.6)"
                >
                  {{ form.touxiji.all_num }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="正常透机：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touxiji.normal"
                    color="rgba(0, 108, 255, 0.6)"
                >
                  {{ form.touxiji.normal }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="待修透机：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touxiji.daixiu"
                    color="rgba(0, 108, 255, 0.6)"
                >
                  {{ form.touxiji.daixiu }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="报废透机：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touxiji.baofei"
                    color="rgba(0, 108, 255, 0.6)"
                >
                  {{ form.touxiji.baofei }}
                </el-progress>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-card>

      <!-- 卡片 -->
      <el-card class="box-card" v-if="role_type == 10">
        <template #header>
          <div class="card-header">
            <!-- 左侧 -->
            <div class="indbody_top"></div>
            <!-- 右侧 -->
            <div class="indbody_text">透析机使用次数</div>
          </div>
        </template>
        <div>
          <el-form :model="form">
            <el-form-item label="使用总数：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touxijinum.all"
                    color="rgba(21, 138, 84, 0.6)"
                >
                  {{ form.touxijinum.all }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="早上使用：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touxijinum.zaoshang"
                    color="rgba(21, 138, 84, 0.6)"
                >
                  {{ form.touxijinum.zaoshang }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="下午使用：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touxijinum.xiawu"
                    color="rgba(21, 138, 84, 0.6)"
                >
                  {{ form.touxijinum.xiawu }}
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="晚上使用：">
              <div class="demo-progress">
                <el-progress
                    :percentage="form.touxijinum.wanshang"
                    color="rgba(21, 138, 84, 0.6)"
                >
                  {{ form.touxijinum.wanshang }}
                </el-progress>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-card>

      <!-- 卡片 -->
      <el-card class="box-card" v-if="role_type == 10">
        <template #header>
          <div class="card-header">
            <!-- 左侧 -->
            <div class="indbody_top"></div>
            <!-- 右侧 -->
            <div class="indbody_text">透析机消毒数</div>
          </div>
        </template>
        <div>
          <el-form :model="form">
            <el-form-item label="透机总数：">
              <div class="demo-progress">
                <el-progress :percentage="50" color="rgba(219, 146, 0, 0.6)">
                  200
                </el-progress>
              </div>
            </el-form-item>
            <el-form-item label="已消毒：" style="margin: 30px 0">
              <div class="demo-progress">
                <el-progress :percentage="50" color="rgba(219, 146, 0, 0.6)"/>
              </div>
            </el-form-item>
            <el-form-item label="未消毒：">
              <div class="demo-progress">
                <el-progress :percentage="50" color="rgba(219, 146, 0, 0.6)"/>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>

    <!-- 底部 -->
    <div class="bottombody" v-if="admin_status == 1">
      <!-- 头部 -->
      <div class="display_flex" style="justify-content: space-between">
        <!-- 左侧 -->
        <div class="display_flex">
          <div class="indbody_top"></div>
          <!-- 标题-->
          <div class="indbody_text">透析患者</div>
        </div>
        <!--  右侧-->
        <div class="card-header">
          <div>总计:</div>
          <div>{{ total }}</div>
        </div>
      </div>
      <!-- 下划线 -->
      <el-divider/>
      <!-- 内容 -->
      <div class="cardflex">
        <!-- 卡片 -->
        <el-card  
            class="box-cards"
            v-for="(items, index) in userlist"
            :key="index"
        >
          <template #header>
            <div class="card-header" @click="ToTXJL(items)" style="justify-content: space-between">
              <!-- 左侧 -->
              <div class="left_play">
                <div>
                  <el-avatar :size="40" :src="items.avatar"/>
                </div>
                <div class="middlebody">
                  <div class="botname">{{ items.name }}</div>
                  <div class="botage">{{ items.age }}岁</div>
                </div>
              </div>
              <!-- 右侧 -->
              <div class="cardright">
                <el-tag v-if="items.dict_code">{{ items.dict_code }}</el-tag>
                <el-tag v-if="items.bed_num">{{ items.bed_num }}</el-tag>
                <el-tag v-if="items.dict_dialysis_type_name">{{
                    items.dict_dialysis_type_name
                  }}
                </el-tag>
                <el-tag v-if="items.dict_tonglu_name">{{
                    items.dict_tonglu_name
                  }}
                </el-tag>
              </div>
            </div>
          </template>
          <!-- 底部内容 -->
          <div>
            <el-steps finish-status="success">
              <el-step
                  title="签到"
                  :status="items.is_sign ? 'success' : ''"
              />
              <el-step
                  title="上机"
                  :status="items.sjz_time ? 'success' : ''"
              />
              <el-step
                  title="核对"
                  :status="items.is_check ? 'success' : ''"
              />
              <el-step title="下机" :status="items.xjz_time ? 'success' : ''"/>
            </el-steps>
          </div>
        </el-card>
      </div>
      <!-- 下划线 -->
      <el-divider/>
      <!-- 分页 -->
      <div style="margin: 10px 0">
        <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="currenchan"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import service from '@/utils/request'
import {TimeYear} from '@/utils/tool.js'
const router = useRouter()
const route = useRoute()
export default {
  name: 'dashboard',
  data() {
    return {
      formInline: {
        region: this.gettime(),
        time: TimeYear(new Date()),
      },
      current: 1,
      form: {
        tonglu: {},
      },
      active: 1,
      total: 0,
      Oncampus: [],
      userlist: [],
      admin_status: null,
      shiduan: [
        {name: '上午', id: 1},
        {name: '下午', id: 2},
        {name: '晚上', id: 3},
        {name: '全天', id: 4},
      ],
      role_type: null,
    }
  },
  mounted() {
    this.role_type = localStorage.getItem('ms_role_type')
    this.hospital()
  },
  methods: {
    // 获取列表
    async tobody() {
      let data = {
        current: this.current,
        size: 10,
        hospital: this.formInline.campus,
        shiduan: this.formInline.region,
        time: this.formInline.time,
      }
      let res = await service.post('/api/Index/index', data)
      if (res.code === 0) {
        if (res.data.size != 0) {
          this.userlist = res.data.data
          this.admin_status = res.data.admin_status
          this.total = res.data.total
          this.form = res.data
        } else {
          this.userlist = []
          this.form = {}
          this.admin_status = res.data.admin_status
          this.total = 0
        }
      }
    },
    // 获取院区
    async hospital() {
      let res = await service.post('/api/hospital/list', {parent_id: 0})
      if (res.code === 0) {
        this.Oncampus = res.data
        this.formInline.campus = res.data[0].id
        this.tobody()
      }
    },
    // 日期查询
    timeline(e) {
      this.formInline.time = TimeYear(e)
      this.tobody()
    },
    // 顶部搜索框
    topInline() {
      this.tobody()
    },
    // 分页点击
    currenchan(e) {
      this.current = e
      this.tobody()
    },
     gettime() {
      const date = new Date()
      const hour = date.getHours()

      if (hour >= 0 && hour < 12) {
        return '上午'
      } else if (hour >= 12 && hour < 18) {
        return  '下午'
      } else {
        return  '晚上'
      }
    },
    ToTXJL(item){
      item.toTX = '1'
      item.YZ = this.formInline.campus
      item.time = this.formInline.time
      item.user = this.formInline.region
      this.$router.push({
          path: '/TXjilu/record',
          query: item
        })
    }
  },
}
</script>

<style scoped>
.top {
  height: 48px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  padding: 10px 0 0 10px;
}

.indbody {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  justify-content: space-between;
}

.cardbody {
  width: 558px;
  height: 236px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  padding: 10px;
  overflow-y: scroll;
  margin: 5px;
}

.display_flex {
  display: flex;
}

.indbody_top {
  width: 6px;
  height: 18px;
  background: #3166ae;
  border-radius: 3px 3px 3px 3px;
  opacity: 1;
  margin-top: 2px;
}

.indbody_text {
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  margin-left: 20px;
}

.demo-progress {
  width: 100%;
}

.card-header {
  display: flex;
  line-height: 21px;
}

.box-card {
  width: 32.5%;
  height: 260px;
  border-radius: 8px 8px 8px 8px;
  margin-bottom: 5px;
}

.box-cards {
  width: 32.5%;
  height: 175px;
  border-radius: 8px 8px 8px 8px;
  margin: 4px;
}

.bottombody {
  height: 644px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  padding: 10px;
}

.botname {
  font-size: 16px;
  font-family: 'Source Han Sans CN-Medium', 'Source Han Sans CN';
  font-weight: 500;
  color: #444444;
}

.botage {
  font-size: 14px;
  font-family: 'Source Han Sans CN-Medium', 'Source Han Sans CN';
  font-weight: 400;
  color: #999999;
}

.cardright {
  text-align: right;
  width: calc(100% - 200px);
}

.cardflex {
  display: flex;
  flex-wrap: wrap;
  height: 440px;
  overflow-y: scroll;
}

.el-tag--default {
  margin: 1px 3px;
}

.middlebody {
  text-align: left;
  margin-left: 10px;
}

.left_play {
  display: flex;
  width: 130px;
}

.formwith {
  width: 100%;
}

/deep/ .el-form-item__content {
  width: 100%;
}
</style>
